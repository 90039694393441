import React, { Suspense, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

const HomePage = lazy(() => import('./pages/HomePage'));
const SubmissionPage = lazy(() => import('./pages/SubmissionPage'));

const App = () => (
  <div className="c-App">
    <Suspense
      fallback={
        <div style={{ background: '#2c2d2e', height: '100%' }} />
          }
    >
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/join" exact component={SubmissionPage} />
      </Switch>
    </Suspense>
  </div>
);

export default withRouter(App);
